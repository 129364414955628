import qs from 'query-string';
import initAuth0, { LINK_ACCOUNT_STATUS_KEY } from '../../../../globals/oidcSettings';
import session from '../../../../utils/session';

export default function* getLinkAccountsStatus(authorizationCodeExists = false) {
  /*
   * NOTE: This is an workaround to solve the Safari ("non-standalone" mode) and Android Auth0 popup login "postMessage" issue.
   * More details here https://support.auth0.com/tickets/00503396.
   * That's why we run this on Safari and Android only for now.
   * The user must be authenticated already in order to link the accounts - session.exists() must return true
   */

  console.log('[getLinkAccountsStatus] Starting');

  const linkAccountsConnectionId = localStorage.getItem('link-social-accounts-connection');

  if (!linkAccountsConnectionId || !authorizationCodeExists || !session.exists()) {
    return null;
  }

  // reset the state
  localStorage.removeItem('link-social-accounts-connection');

  try {
    const socialAuth0Client = yield initAuth0(true);

    // Remove only the local information, otherwise an additional call to
    // the auth0 token API will be made, which is invalid
    yield socialAuth0Client.logout({ localOnly: true });

    yield socialAuth0Client.handleRedirectCallback();

    const token = yield socialAuth0Client.getIdTokenClaims();
    const linkStatus = token[LINK_ACCOUNT_STATUS_KEY];

    localStorage.setItem('linkAccountsStatus', linkStatus);

    const queryString = qs.parse(window.location.search);
    queryString.linkSocialAccount = 1;

    if (
      Object.prototype.hasOwnProperty.call(queryString, 'code') &&
      Object.prototype.hasOwnProperty.call(queryString, 'state')
    ) {
      delete queryString.code;
      delete queryString.state;

      // reload the page with the new params string
      window.location.search = qs.stringify(queryString);
    }

    return linkStatus;
  } catch (e) {
    console.log('[getLinkAccountsStatus] Error:', e);
  }

  return null;
}
