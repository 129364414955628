import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import colors, { validColors } from '../../globals/colors';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import PersonRepresentation from '../PersonRepresentation/PersonRepresentation';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import styles from './UserUpdateConfirmation.scss';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Color from '../../styles/constants/_colors.scss';
import { buttonTypes } from '../Button/Button';

function UserUpdateConfirmation({
  localizedContent: { userUpdateConfirmation: content },
  title,
  subtitle,
  users,
  actions,
  onSubmit,
  color
}) {
  return (
    <ScrollContainer
      headerContent={
        <header className="gin-top2 gin-bot2">
          <PopoutPanelIconHeading type={types.VALID} title={title} subtitle={subtitle} />
        </header>
      }
      footerContent={
        <ActionList>
          {actions.map(({ text, action = null, loading = false, redirectUrl = null, target = null }, i) => (
            <Action
              key={i}
              label={text}
              onClick={action}
              loading={loading}
              disabled={loading}
              redirectUrl={redirectUrl}
              target={target}
              type={buttonTypes.ACTION_NO_ICON}
            />
          ))}
          <Action label={content.button_done} onClick={onSubmit} primary />
        </ActionList>
      }
    >
      <div className="pad2 gin-top2 gin-bot2">
        {users.map(({ firstName, lastName, username }, i) => (
          <div key={i} className={classnames('gin-bot2', styles.user)}>
            <PersonRepresentation
              thumbnailSize={thumbnailSizes.TABLE}
              thumbnailColor={color || colors.PROFILE}
              name={`${firstName} ${lastName}`}
              statusText={username}
            />
            <div aria-hidden="true" className={classnames(styles.status)}>
              <SVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE} fill={Color['#border-color']} />
            </div>
          </div>
        ))}
      </div>
    </ScrollContainer>
  );
}

UserUpdateConfirmation.propTypes = {
  localizedContent: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  color: PropTypes.oneOf(validColors),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    })
  ).isRequired,
  actions: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default withLocalizedContent('userUpdateConfirmation')(UserUpdateConfirmation);
