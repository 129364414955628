import PropTypes from 'prop-types';
import React from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Headers.scss';

import { featureIsEnabled } from '../../globals/envSettings';
import GradebookTable from '../GradebookTable/GradebookTable';

const ROW_HEIGHT = 58;
function Headers({ levels = [], location, localizedContent = {} }) {
  return (
    <GradebookTable.Row>
      <GradebookTable.Header height={ROW_HEIGHT} stickyColumn>
        &nbsp;
      </GradebookTable.Header>
      <GradebookTable.Header height={ROW_HEIGHT} width={170} textAlign="left">
        {featureIsEnabled('replacing-last-accessed-with-last-opened') ? (
          <div>Last Opened</div>
        ) : (
          <div>Last Accessed</div>
        )}
      </GradebookTable.Header>
      <GradebookTable.Header height={ROW_HEIGHT} colSpan={2}>
        Total
      </GradebookTable.Header>
      {levels.map(({ name, uId, isActivity, locked }) => (
        <GradebookTable.Header height={ROW_HEIGHT} key={uId} colSpan={2} locked={locked}>
          {!isActivity ? (
            <TextLink
              to={`${location.pathname.replace(/\/$/, '')}#${encodeURIComponent(uId)}`}
              component={RouterLink}
              {...(locked && {
                dataAttributes: { tooltip: localizedContent?.locked_node_tooltip_text || '' },
                leftIcon: <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
              })}
            >
              {name}
            </TextLink>
          ) : (
            <span
              {...(locked && {
                className: styles.lockedIcon,
                'data-tooltip': localizedContent?.locked_node_tooltip_text
              })}
            >
              {locked && <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />}
              {name}
            </span>
          )}
        </GradebookTable.Header>
      ))}
    </GradebookTable.Row>
  );
}
Headers.propTypes = {
  levels: PropTypes.array,
  location: PropTypes.object.isRequired,
  localizedContent: PropTypes.object
};

export default withRouter(Headers);
