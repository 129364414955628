/* eslint sort-keys: "error" */

// called "context" in Hub - effectively a new enum "user role category"
const ACTION_CONTEXTS = {
  ORG_STAFF: 'ORG_STAFF',
  ORG_STUDENTS: 'ORG_STUDENTS',
  OUP_ADMIN: 'OUP_ADMIN', // be super super careful this means the category NOT the role
  OUP_STAFF: 'OUP_STAFF'
};

const PROFILE_MAPPING = {
  practice: '5',
  presentation: '4',
  'presentation-quiz': '9',
  quiz: '8',
  submission: '6',
  submit2practice: '7'
};

const APP_CONSTANTS = {
  ACCESS_CODE_TYPES: {
    CLASS: 'CLASS',
    PLACEMENT_TEST: 'PLACEMENT_TEST',
    PRODUCT: 'PRODUCT'
  },
  ADD_ORGANIZATION_LICENCES_HELP_LINK:
    'https://support.englishhub.oup.com/administrators/#add-organization-licences-for-course-materials-to-a-class',

  ADD_STUDENT: 'addStudent',
  ADD_SUPERVISED_USERS_LINK:
    'https://support.englishhub.oup.com/administrators/#add-a-student-to-an-organization-without-an-email-address',

  AM_SYNC_RESULT_STATUS: {
    FAILED: 'FAILED',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS'
  },

  API_VERSIONS: {
    API_VERSION_0_1: 'application/x.oup-com.eps.v0.1+json',
    API_VERSION_0_2: 'application/x.oup-com.eps.v0.2+json',
    API_VERSION_0_3: 'application/x.oup-com.eps.v0.3+json',
    API_VERSION_BASE: 'application/json', // base api version used before versioning impl
    API_VERSION_LATEST: 'application/x.oup-com.eps+json'
  },

  ASSIGNMENT_GROUP: 'ASSIGNMENT_GROUP',

  ...ACTION_CONTEXTS,

  ASSIGN_LICENCES: '/support/articles/classes#assigning-licences',
  AUTH0: 'auth0',
  BASE_GLOSSARY_NAME: 'sharedTerms',

  BULK_UPLOAD: {
    COLUMN_ERROR_MAP: {
      1: 'INVALID_FIRSTNAME',
      101: 'NO_FIRSTNAME',
      102: 'NO_LASTNAME',
      103: 'NO_EMAIL',
      104: 'NO_ROLE',
      105: 'INVALID_CLASS_TOO_LONG',
      2: 'INVALID_LASTNAME',
      3: 'INVALID_EMAIL',
      4: 'INVALID_ROLE',
      5: 'INVALID_CLASS',
      6: 'INVALID_HEADER_ROW_ONLY',
      LINE_EMPTY: 'LINE_EMPTY'
    },
    ERROR_MAP: {
      INVALID_EMPTY_FILE: 'INVALID_EMPTY_FILE',
      INVALID_HEADER_ROW: 'INVALID_HEADER_ROW',
      INVALID_NUMBER_OF_COLUMNS: 'INVALID_NUMBER_OF_COLUMNS',
      INVALID_TOO_MANY_RECORDS: 'INVALID_TOO_MANY_RECORDS'
    },
    MAX_ROWS: 200
  },

  BUY_OXFORD_PLACEMENT_TESTS_LINK:
    'https://elt.oup.com/cat/subjects/placement-tests/?groupid=028214&cc=gb&selLanguage=en',

  CLASS_ONBOARDING_WIZARD: {
    CLASS: {
      VALIDATE_CLASS_STATUSES: {
        EXISTS: 'EXISTS',
        FAILED: 'FAILED',
        INVALID_CLASS_NAME: 'INVALID_CLASS_NAME',
        SUCCESS: 'SUCCESS'
      }
    }
  },

  COLLECTION_LIST: 'COLLECTIONS',
  COOKIES_MAX_AGE: Date.now() + 1000 * 60 * 60 * 24 * 365 * 50,
  EAC_SYSTEM_IDS: {
    AM: 'am',
    CBA: 'elt_hub', // TBC
    DLP: 'dlp',
    ECOMM: 'elt_ecommerce', // OALD is in here because it has no separate ID system
    ELTCORE: 'elt_hub',
    ELTCOURSE: 'elt_hub',
    MFL: 'mfl',
    NGS: '', // On "pause"
    OIC: 'oic',
    OLB: 'elt_olb',
    ORB: 'orb',
    PREMIUM: 'elt_premium_download',
    VST: 'elt_vst'
  },

  EPS_ASSETS_BASE_ELT_APPS: '/content/apps/elt',
  EPS_ASSETS_BASE_ELT_AUTHOR: '/content/author/resources',
  EPS_ASSETS_BASE_ELT_MISC: '/content/misc/elt',

  FIRST_USE: 'FIRST_USE',
  HUB_IMPORT_ACCESS_CODE_HELP_LINK: '/support/articles/give-learning-material-to-a-student-or-teacher-with-a-licence',
  HUB_REDEEM_CODE_HELP_LINK: 'https://support.englishhub.oup.com/students/#use-a-code',
  HUB_SUPPORT_ADD_TEACHER_OR_OTHER_STAFF_TO_ORGANIZATION:
    'https://support.englishhub.oup.com/administrators/#add-a-teacher-or-other-staff-to-an-organization',
  HUB_SUPPORT_CONTACT_US: 'https://support.englishhub.oup.com/',
  HUB_SUPPORT_IMPORT_ACCESS_CODES_URL: 'https://support.englishhub.oup.com/administrators/#import-add-access-codes',
  HUB_SUPPORT_JOINING_CODE_FOR_STUDENTS_URL:
    'https://support.englishhub.oup.com/administrators/#give-students-a-code-to-join-a-placement-group',
  HUB_SUPPORT_OXFORD_ID: 'https://support.englishhub.oup.com/what-is-an-oxford-id/',
  HUB_SUPPORT_PLACEMENT_TESTS_EXTRA_TIME_URL: '#',
  HUB_SUPPORT_PLACEMENT_TESTS_URL: 'https://support.englishhub.oup.com/administrators/#placement-test',
  HUB_SUPPORT_REGISTER_ORGANISATION_URL:
    'https://support.englishhub.oup.com/administrators/#register-add-an-organization',
  HUB_SUPPORT_USERNAME_LINK:
    'https://support.englishhub.oup.com/administrators/#add-a-student-to-an-organization-with-email',
  HUB_SUPPORT_WHAT_IS_A_LICENSE_LINK: 'https://support.englishhub.oup.com/administrators/#what-is-a-licence',

  IMPORT_ACCESS_CODE_HELP_LINK: '/support/articles/licences',

  INSP_SUPPORT_HELP_LINK: 'https://global.oup.com/education/content/secondary/faqs/digital-inspection-copy-faqs',

  JSON_ENCODED: 'application/json',

  LEARNER_HUB_REDEEM_CODE_HELP_LINK: 'https://support.englishhub.oup.com/students/#use-a-code',

  LOR_CONSTANTS: {
    ALLOWED_PUBLISH_TYPES: ['live', 'test'],
    ALLOWED_RESOURCE_TYPES: ['Word package', 'Quad Assessment', 'Online Course']
  },
  MFL_SUPPORT_LINK: '/support/mfl',

  NO_CURRICULUM: 'NO_CURRICULUM',

  NO_DATA_HERE: 'N/A',
  NUMBER_TO_RENDER_MAIN: 0,
  OPT_PRODUCTS_PLATFORM_CODE: 'am',
  ORG_ADMIN_HUB_REDEEM_CODE_HELP_LINK: 'https://support.englishhub.oup.com/administrators/#use-a-code',

  ORG_ADMIN_HUB_SET_ORGANIZATION_HELP_LINK:
    'https://support.englishhub.oup.com/administrators/#create-your-organization',

  ORG_MAX_CREDITS: 1000000,

  ORG_REGISTRATION_CONTEXT: {
    CES: 'CES',
    OUP_CUSTOMER_SUPPORT: 'OUP_CUSTOMER_SUPPORT',
    PLACEMENT: 'PLACEMENT'
  },

  ORG_REGISTRATION_STATUS: {
    PARTIALLY_REGISTERED: 'PARTIALLY_REGISTERED',
    REGISTERED: 'REGISTERED'
  },

  ORG_STATUS: {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED'
  },

  OS_ANDROID: 'Android',
  OS_IOS: 'iOS',
  OTE_TEST_USER_EMAIL_PREFIX: 'testaces12+ote-',
  PLACEMENT_TEST_STATUS: {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    DRAFT: 'DRAFT',
    MISSING: 'MISSING',
    PROCESSING: 'PROCESSING',
    UPCOMING: 'UPCOMING'
  },

  PLATFORMS: {
    CBA: 'CBA',
    CES: 'CES',
    CMDP: 'CMDP',
    DIZIO_ANDROID: 'DIZIO_ANDROID',
    DIZIO_IOS: 'DIZIO_IOS',
    ELTCORE: 'ELTCORE',
    EPS_CONSOLE: 'EPS_CONSOLE',
    HUB: 'HUB',
    INSP: 'INSP',
    KERBOODLE: 'KERBOODLE',
    LMS_HUB: 'LMS_HUB',
    OALD: 'OALD',
    OALD_ANDROID: 'OALD_ANDROID',
    OALD_IOS: 'OALD_IOS',
    OIC: 'OIC',
    OLB: 'OLB',
    OLB_MOBILE: 'OLB_MOBILE',
    OLD_ANDROID: 'OLD_ANDROID',
    OLD_IOS: 'OLD_IOS',
    OPT: 'OPT',
    ORB: 'ORB',
    ORB_MOBILE: 'ORB_MOBILE',
    OTE: 'OTE',
    OWL: 'OWL',
    RVS: 'RVS',
    SMART: 'SMART',
    VST: 'VST'
  },

  PLATFORM_CODES: {
    CBA: 'cba',
    CES: 'ces',
    CMDP: 'cmdp',
    ELTCORE: 'eltcore',
    ELTCOURSE: 'eltcourse',
    HUB: 'hub',
    INSP: 'insp',
    KERBOODLE: 'kerboodle',
    LTI_HUB: 'lti_hub',
    OALD: 'oald',
    OIC: 'oic',
    OLB: 'olb',
    OPT: 'opt',
    ORB: 'orb',
    OTE: 'ote',
    OUP: 'oup', // unknown OUP platform context, e.g. when T&Cs accepted but context not saved
    RVS: 'rvs',
    SMART: 'smart',
    VST: 'vst'
  },

  PLATFORM_DOMAINS: {
    EPS: 'account.oup.com',
    HUB: 'englishhub.oup.com',
    HUB_LEGACY: 'hub.edu.oup.com',
    HUB_LMS: 'lti.englishhub.oup.com',
    INSP: 'onlineinspection.edu.oup.com',
    LOCALHOST: 'localhost',
    OALD: 'oxfordlearnersdictionaries.com',
    OIC: 'oic.edu.oup.com',
    OISA: 'oisa.oup.com',
    OLB: 'oxfordlearnersbookshelf.com',
    ORB: 'oxfordreadingbuddy.com',
    ORB_EPS: 'oxfordreadingbuddy.oup.com',
    OTE: 'oxfordtestofenglish.oup.com',
    RVS: 'resultsverification.oup.com',
    VST: 'oxfordonlinepractice.com'
  },

  PRODUCT_LIST: 'PRODUCTS',

  PRODUCT_TARGET_USERTYPE: {
    STUDENT: 'student',
    TEACHER: 'teacher'
  },

  PRODUCT_TYPE_DICTIONARY: 'dictionary',
  PRODUCT_TYPE_EBOOK: 'ebook',
  PROFILE_MAPPING: { ...PROFILE_MAPPING },
  RECAPTCHA_ACTIONS: {
    REDEEM_CODE: 'redeem_code',
    UPLOAD_CERTIFICATE: 'upload_certificate'
  },
  REDEEM_CODE: 'REDEEM_CODE',
  REDEEM_CODE_HELP_LINK: '/support/articles/how-do-i-redeem-my-code',
  REDEEM_CODE_PURCHASE_LINK: 'https://elt.oup.com/cat/?utm_source=elt-hub',

  RESOURCE_TYPE: {
    ASSESSMENT: 'ASSESSMENT',
    COURSE_RESOURCE: 'COURSE_RESOURCE'
  },

  SHOW_EXPIRES_TODAY_MESSAGE_PERIOD_DAY: 1,
  SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS: 30,

  SIGN_CARD_TEMPLATE: {
    SELF_MANAGED: 'SELF_MANAGED',
    SUPERVISED: 'SUPERVISED'
  },

  TEACHER: 'teacher',
  TEACHER_ADMIN_HUB_REDEEM_CODE_HELP_LINK: 'https://support.englishhub.oup.com/administrators/#use-a-code',
  TEACHER_HUB_REDEEM_CODE_HELP_LINK: 'https://support.englishhub.oup.com/teachers/#use-a-code',
  URL_HIERARCHY_DELIMITER: ':/:',

  USER_STATUS: {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED'
  }
};

module.exports = APP_CONSTANTS;
