import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { orgSupportsSchoolCode, orgSupportsLocation } from '@oup/shared-node-browser/org.js';

import { featureIsEnabled } from '../../globals/envSettings';
import Dropdown from '../Dropdown/Dropdown';
import OrgUniqueIdGenerationField from '../OrgUniqueIdGenerationField/OrgUniqueIdGenerationField';
import TextInput from '../TextInput/TextInput';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import ToggleSwitch, { toggleTypes } from '../ToggleSwitch/ToggleSwitch';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import ElementWithText from '../ElementWithText/ElementWithText';
import IconEmblem from '../IconEmblem/IconEmblem';
import { GLYPHS } from '../SVGIcon/SVGIcon.js';
import { isHubMode, optInLti } from '../../utils/platform';

import styles from './OrgFormBody.scss';

function OrgFormBody({
  localizedContent: content,
  localizedCurriculumTypeNames: curriculumTypeNames,
  roles = {},
  countries = {},
  curriculumTypes = {},
  hearAboutUsValues = {},
  adminUser = { email: '' },
  name = '',
  role = '',
  countryCode = '',
  orgRegion = '',
  orgPostcode = '',
  curriculumType = '',
  orgUniqueId = '',
  primaryEmail = '',
  webAddress = '',
  isLmsLtiTool = false,
  province = '',
  taxRegistration = '',
  sapNumber = '',
  department = '',
  hearAboutUs = '',
  address = '',
  city = '',
  disabled = [],
  hidden = [],
  preview = false,
  errors = {},
  createChangeHandler = () => {},
  createBlurHandler = () => {},
  generateOrgUniqueId = () => {}
}) {
  // -- decide between adding or editing organisation
  const [isLmsLtiToolInitialValue] = useState(isLmsLtiTool);

  return (
    <div>
      {!hidden.includes('adminUser.email') ? (
        <ValidationStatus
          forId="adminUser.email"
          isActive={!preview ? errors.adminUser && errors.adminUser.email : null}
          message={content.admin_user_email_error}
        >
          <TextInput
            id="adminUser.email"
            label={content.admin_user_email_label}
            placeholder={content.admin_user_email_placeholder}
            value={adminUser.email}
            disabled={disabled.includes('adminUser.email')}
            readOnly={preview}
            onKeyUp={createChangeHandler('adminUser.email')}
            onChange={createChangeHandler('adminUser.email')}
            onBlur={createBlurHandler('adminUser.email')}
          />
          <p className="pad-top1">{content.appropriate_permissions_notice}</p>
        </ValidationStatus>
      ) : null}

      {!hidden.includes('name') ? (
        <ValidationStatus forId="name" isActive={!preview ? errors.name : null} message={content.name_error}>
          <TextInput
            id="name"
            label={content.name_label}
            placeholder={content.name_placeholder}
            value={name}
            disabled={disabled.includes('name')}
            readOnly={preview}
            onKeyUp={createChangeHandler('name')}
            onChange={createChangeHandler('name')}
            onBlur={createBlurHandler('name')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('department') ? (
        <ValidationStatus
          forId="department"
          isActive={!preview ? errors.department : null}
          message={content.department_error}
        >
          <TextInput
            id="department"
            label={content.department_label}
            placeholder={content.department_placeholder}
            value={department}
            disabled={disabled.includes('department')}
            readOnly={preview}
            onKeyUp={createChangeHandler('department')}
            onChange={createChangeHandler('department')}
            onBlur={createBlurHandler('department')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('address') ? (
        <ValidationStatus forId="address" isActive={!preview ? errors.address : null} message={content.address_error}>
          <TextInput
            id="address"
            label={content.address_label}
            placeholder={content.address_placeholder}
            value={address}
            disabled={disabled.includes('address')}
            readOnly={preview}
            onKeyUp={createChangeHandler('address')}
            onChange={createChangeHandler('address')}
            onBlur={createBlurHandler('address')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('city') ? (
        <ValidationStatus forId="city" isActive={!preview ? errors.city : null} message={content.city_error}>
          <TextInput
            id="city"
            label={content.city_label}
            placeholder={content.city_placeholder}
            value={city}
            disabled={disabled.includes('city')}
            readOnly={preview}
            onKeyUp={createChangeHandler('city')}
            onChange={createChangeHandler('city')}
            onBlur={createBlurHandler('city')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('role') ? (
        <ValidationStatus forId="role" isActive={!preview ? errors.role : null} message={content.role_error}>
          <Dropdown
            id="role"
            name="role"
            label={content.role_label}
            value={role}
            options={Object.entries(roles).map(([value, text]) => ({
              text,
              value
            }))}
            disabled={disabled.includes('role')}
            readOnly={preview}
            onChange={createChangeHandler('role')}
            onBlur={createBlurHandler('role')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('countryCode') ? (
        <ValidationStatus
          forId="countryCode"
          isActive={!preview ? errors.countryCode : null}
          message={content.country_error}
        >
          <Dropdown
            id="countryCode"
            name="countryCode"
            label={content.country_label}
            value={countryCode}
            options={[
              { value: '', text: content.country_default_option },
              ...Object.entries(countries).map(([value, text]) => ({ text, value }))
            ]}
            disabled={disabled.includes('countryCode')}
            readOnly={preview}
            onChange={createChangeHandler('countryCode')}
            onBlur={createBlurHandler('countryCode')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('province') ? (
        <ValidationStatus
          forId="province"
          isActive={!preview ? errors.province : null}
          message={content.province_error}
        >
          <TextInput
            id="province"
            label={content.province_label}
            placeholder={content.province_placeholder}
            value={province}
            disabled={disabled.includes('province')}
            readOnly={preview}
            onKeyUp={createChangeHandler('province')}
            onChange={createChangeHandler('province')}
            onBlur={createBlurHandler('province')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('curriculumType') && orgSupportsSchoolCode(role) ? (
        <div>
          <ValidationStatus
            forId="curriculumType"
            isActive={!preview ? errors.curriculumType : null}
            message={content.curriculum_type_error}
          >
            <Dropdown
              id="curriculumType"
              name="curriculumType"
              label={content.curriculum_type_label}
              value={curriculumType}
              options={[
                { value: '', text: content.default_no_value_option },
                ...Object.keys(curriculumTypes).map(value => ({
                  value,
                  text: curriculumTypeNames[`curriculum_${value}`]
                }))
              ]}
              disabled={disabled.includes('curriculumType')}
              readOnly={preview}
              onChange={createChangeHandler('curriculumType')}
              onBlur={createBlurHandler('curriculumType')}
            />
          </ValidationStatus>
          <p className="gin-top1">{content.curriculum_type_description}</p>
          {orgSupportsSchoolCode(role) && (
            <OrgUniqueIdGenerationField
              orgUniqueId={orgUniqueId}
              disabled={disabled.includes('orgUniqueId')}
              readOnly={preview}
              onSubmit={generateOrgUniqueId}
            />
          )}
        </div>
      ) : null}

      {!hidden.includes('primaryEmail') ? (
        <div>
          <ValidationStatus
            forId="primaryEmail"
            isActive={!preview ? errors.primaryEmail : null}
            message={content.primary_email_error}
          >
            <TextInput
              id="primaryEmail"
              label={content.primary_email_label}
              placeholder={content.primary_email_placeholder}
              value={primaryEmail}
              disabled={disabled.includes('primaryEmail')}
              readOnly={preview}
              onKeyUp={createChangeHandler('primaryEmail')}
              onChange={createChangeHandler('primaryEmail')}
              onBlur={createBlurHandler('primaryEmail')}
            />
          </ValidationStatus>
          <p className="gin-top1">{content.primary_email_description}</p>
        </div>
      ) : null}

      {!hidden.includes('webAddress') ? (
        <ValidationStatus
          forId="webAddress"
          isActive={!preview ? errors.webAddress : null}
          message={content.web_address_error}
        >
          <TextInput
            id="webAddress"
            label={content.web_address_label}
            placeholder={content.web_address_placeholder}
            value={webAddress}
            disabled={disabled.includes('webAddress')}
            readOnly={preview}
            onKeyUp={createChangeHandler('webAddress')}
            onChange={createChangeHandler('webAddress')}
            onBlur={createBlurHandler('webAddress')}
          />
        </ValidationStatus>
      ) : null}

      {featureIsEnabled('hub-self-service') && optInLti() && !hidden.includes('isLmsLtiTool') && isHubMode() ? (
        <div>
          <TextInputLabel id="isLmsLtiTool" label="LMS integration" />
          <p className="gin-top1">{content.lms_lti_tool_description}</p>
          <ValidationStatus forId="isLmsLtiTool" isActive={!preview ? errors.isLmsLtiTool : null}>
            <ToggleSwitch
              id="useCompletedOnly"
              type={toggleTypes.DEFAULT}
              label={<span style={{ fontWeight: 'normal' }}>{content.lms_lti_tool_agreement}</span>}
              value={isLmsLtiTool}
              onChange={async value => {
                await createChangeHandler('isLmsLtiTool')(value);
              }}
              blockLabelBefore
            />
          </ValidationStatus>
          {!isLmsLtiTool && isLmsLtiToolInitialValue && (
            <ElementWithText text={<strong className={styles.alert}>This cannot be undone once you save</strong>}>
              <IconEmblem icon={GLYPHS.ICON_WARNING_CIRCLE} colour="none" />
            </ElementWithText>
          )}
        </div>
      ) : null}

      {!hidden.includes('taxRegistration') ? (
        <ValidationStatus
          forId="taxRegistration"
          isActive={!preview ? errors.taxRegistration : null}
          message={content.tax_registration_error}
        >
          <TextInput
            id="taxRegistration"
            label={content.tax_registration_label}
            placeholder={content.tax_registration_placeholder}
            value={taxRegistration}
            disabled={disabled.includes('taxRegistration')}
            readOnly={preview}
            onKeyUp={createChangeHandler('taxRegistration')}
            onChange={createChangeHandler('taxRegistration')}
            onBlur={createBlurHandler('taxRegistration')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('sapNumber') ? (
        <ValidationStatus
          forId="sapNumber"
          isActive={!preview ? errors.sapNumber : null}
          message={content.sap_number_error}
        >
          <TextInput
            id="sapNumber"
            label={content.sap_number_label}
            value={sapNumber}
            disabled={disabled.includes('sapNumber')}
            readOnly={preview}
            onKeyUp={createChangeHandler('sapNumber')}
            onChange={createChangeHandler('sapNumber')}
            onBlur={createBlurHandler('sapNumber')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('orgRegion') && orgSupportsLocation(countryCode) ? (
        <ValidationStatus
          forId="orgRegion"
          isActive={!preview ? errors.orgRegion : null}
          message={content.org_region_error}
        >
          <TextInput
            id="orgRegion"
            label={content.org_region_label}
            value={orgRegion}
            disabled={disabled.includes('orgRegion')}
            readOnly={preview}
            onKeyUp={createChangeHandler('orgRegion')}
            onChange={createChangeHandler('orgRegion')}
            onBlur={createBlurHandler('orgRegion')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('orgPostcode') && orgSupportsLocation(countryCode) ? (
        <ValidationStatus
          forId="orgPostcode"
          isActive={!preview ? errors.orgPostcode : null}
          message={content.org_postcode_error}
        >
          <TextInput
            id="orgPostcode"
            label={content.org_postcode_label}
            value={orgPostcode}
            disabled={disabled.includes('orgPostcode')}
            readOnly={preview}
            onKeyUp={createChangeHandler('orgPostcode')}
            onChange={createChangeHandler('orgPostcode')}
            onBlur={createBlurHandler('orgPostcode')}
          />
        </ValidationStatus>
      ) : null}

      {!hidden.includes('hearAboutUs') ? (
        <ValidationStatus
          forId="hearAboutUs"
          isActive={!preview ? errors.hearAboutUs : null}
          message={content.hearAboutUs_error}
        >
          <Dropdown
            id="hearAboutUs"
            name="hearAboutUs"
            label={content.hearAboutUs_label}
            value={hearAboutUs}
            options={[
              { value: '', text: content.hearAboutUs_default_option },
              ...Object.entries(hearAboutUsValues).map(([value, text]) => ({ text, value }))
            ]}
            disabled={disabled.includes('hearAboutUs')}
            readOnly={preview}
            onChange={createChangeHandler('hearAboutUs')}
            onBlur={createBlurHandler('hearAboutUs')}
          />
        </ValidationStatus>
      ) : null}
    </div>
  );
}

OrgFormBody.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  localizedCurriculumTypeNames: PropTypes.object.isRequired,
  roles: PropTypes.object,
  countries: PropTypes.object,
  hearAboutUsValues: PropTypes.object,
  curriculumTypes: PropTypes.object,
  adminUser: PropTypes.shape({ email: PropTypes.string }),
  name: PropTypes.string,
  role: PropTypes.string,
  countryCode: PropTypes.string,
  orgRegion: PropTypes.string,
  orgPostcode: PropTypes.string,
  curriculumType: PropTypes.string,
  orgUniqueId: PropTypes.string,
  primaryEmail: PropTypes.string,
  webAddress: PropTypes.string,
  province: PropTypes.string,
  isLmsLtiTool: PropTypes.bool,
  taxRegistration: PropTypes.string,
  sapNumber: PropTypes.string,
  department: PropTypes.string,
  hearAboutUs: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  disabled: PropTypes.arrayOf(PropTypes.string),
  hidden: PropTypes.arrayOf(PropTypes.string),
  preview: PropTypes.bool,
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func,
  createBlurHandler: PropTypes.func,
  generateOrgUniqueId: PropTypes.func
};

export default OrgFormBody;
