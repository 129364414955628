import * as t from '../actionTypes';

export const generateDownloadLinksRequest = input => ({
  type: t.GENERATE_DOWNLOAD_LINKS_REQUEST,
  payload: input
});

export const generateDownloadLinksSuccess = input => ({
  type: t.GENERATE_DOWNLOAD_LINKS_SUCCESS,
  payload: input
});

export const generateDownloadLinksFailure = input => ({
  type: t.GENERATE_DOWNLOAD_LINKS_FAILURE,
  payload: input
});
