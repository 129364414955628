/* eslint-disable */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ENTITY_TYPES from '../../../globals/entityTypes';
import EntityPageHeader from '../../../components/EntityPageHeader/EntityPageHeader';
import UrlControlledTabs from '../../../components/UrlControlledTabs/UrlControlledTabs';
import withDataRecency from '../../../dataRecency/withDataRecency';
import colors from '../../../globals/colors';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { createAuthorizeUpdateAssignments } from '../../../redux/selectors/authorization/organization';
import ClassroomsTab from '../tabs/ClassroomsTab/ClassroomsTab';
import LibraryTab from '../tabs/LibraryTab/LibraryTab';
import StaffTab from '../tabs/StaffTab/StaffTab';
import StudentsTab from '../tabs/StudentsTab/StudentsTab';
import SubscriptionsTab from '../tabs/SubscriptionsTab/SubscriptionsTab';
import OicProductsTab from '../tabs/OicProductsTab/OicProductTab';
import OicAssessmentsTab from '../tabs/OicAssessmentsTab/OicAssessmentsTab';
import { orgRoles } from '../../../globals/orgRoles';
import { isEltAssessmentMode, isOicMode } from '../../../utils/platform';
import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';
import { isOrgAdmin } from '@oup/shared-node-browser/user';

function ActiveOrganisation({
  localizedContent: { mySchoolPage: content },
  location,
  params,
  archived,
  orgUniqueId,
  organisationId: orgId,
  name,
  userRole,
  orgRole,
  subscriptions,
  isDefaultSearch,
  canManageAssignments,
  orgStudentsDataRecency,
  orgStaffDataRecency,
  orgClassesDataRecency,
  orgLicencesDataRecency,
  orgSubscriptionsDataRecency,
  openEditPanel,
  orgDetails
}) {
  const isOrbOrg = orgRole === orgRoles.PRIMARY_SCHOOL;
  const isOicOrg = orgRole === orgRoles.OIC_SCHOOL;
  const isOrgAdminRoleOnOicPlatform = isOrgAdmin(userRole) && isOicMode();

  const showOicTabs = isOrgAdmin(userRole) && isOicOrg;
  const showStudentsTab = !isOicOrg;
  const showClassesTab = !isEltAssessmentMode() && !isOicOrg;
  const showSubscriptionsTab =
    !isEltAssessmentMode() && !isOrgAdminRoleOnOicPlatform && (Object.keys(subscriptions).length || !isDefaultSearch);
  const showLibraryTab = !isEltAssessmentMode() && !isOrbOrg && !isOicOrg;
  const { orgConfig: { configurations: { CONFIGURATION_JANISON: janisonConfiguration = false } = {} } = {} } = orgDetails;
  const isOrgLinkedToJanison = janisonConfiguration !== false;

  const items = [
    {
      urlName: 'oisa-assessments',
      visible: showOicTabs,
      tabText: content.tabs_assessments_text,
      isContentLoading: orgClassesDataRecency.syncing,
      isContentLoaded: orgClassesDataRecency.synced,
      panelContent: (
        <OicAssessmentsTab
          orgId={orgId}
          tabName={params.tabName}
          panelName={params.panelName}
          canManageAssignments={canManageAssignments}
          isOrgLinkedToJanison={isOrgLinkedToJanison}
        />
      )
    },
    {
      urlName: 'products',
      visible: showOicTabs,
      tabText: content.tabs_products_text,
      isContentLoading: orgClassesDataRecency.syncing,
      isContentLoaded: orgClassesDataRecency.synced,
      panelContent: (
        <OicProductsTab orgId={orgId} tabName={params.tabName} panelName={params.panelName} isOicOrg={isOicOrg} />
      )
    },
    {
      urlName: 'students',
      visible: showStudentsTab,
      tabText: content.tabs_students_text,
      isContentLoading: orgStudentsDataRecency.syncing,
      isContentLoaded: orgStudentsDataRecency.synced,
      panelContent: (
        <StudentsTab
          orgId={orgId}
          tabName={params.tabName}
          panelName={params.panelName}
          canManageAssignments={canManageAssignments}
        />
      )
    },
    {
      urlName: 'schoolStaff',
      visible: true,
      tabText: content.tabs_schoolStaff_text,
      isContentLoading: orgStaffDataRecency.syncing,
      isContentLoaded: orgStaffDataRecency.synced,
      panelContent: (
        <StaffTab
          orgId={orgId}
          tabName={params.tabName}
          panelName={params.panelName}
          canManageAssignments={canManageAssignments}
        />
      )
    },
    {
      urlName: 'classes',
      visible: showClassesTab,
      tabText: content.tabs_classes_text,
      isContentLoading: orgClassesDataRecency.syncing,
      isContentLoaded: orgClassesDataRecency.synced,
      panelContent: (
        <ClassroomsTab orgId={orgId} tabName={params.tabName} panelName={params.panelName} orgRole={orgRole} />
      )
    },
    {
      urlName: 'subscriptions',
      visible: showSubscriptionsTab,
      tabText: content.tabs_subscriptions_text,
      isContentLoading: orgSubscriptionsDataRecency.syncing,
      isContentLoaded: orgSubscriptionsDataRecency.synced,
      panelContent: <SubscriptionsTab orgId={orgId} tabName={params.tabName} panelName={params.panelName} />
    },
    {
      urlName: 'library',
      visible: showLibraryTab,
      tabText: content.tabs_library_text,
      isContentLoading: orgLicencesDataRecency.syncing,
      isContentLoaded: orgLicencesDataRecency.synced,
      panelContent: <LibraryTab orgId={orgId} tabName={params.tabName} params={params} />
    }
  ].filter(({ visible }) => visible);

  return (
    <div className="pad-bot8">
      <div className="color-primary-dark">
        <EntityPageHeader
          entityType={archived ? ENTITY_TYPES.ARCHIVED_ORGANISATION : ENTITY_TYPES.ORGANISATION}
          entityTitle={name}
          entitySubtitle={orgUniqueId}
          editButtonOnClick={openEditPanel}
          displayEditButton={!archived && roleIsAtLeast(userRoles.ORG_ADMIN, userRole)}
          userRole={userRole}
          orgRole={orgRole}
        />
      </div>
      <UrlControlledTabs
        backgroundColor={archived ? colors.ARCHIVED : colors.PRIMARY_BLUE}
        tabName={params.tabName}
        pathname={location.pathname}
        items={items}
      />
    </div>
  );
}

ActiveOrganisation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  location: PropTypes.any,
  params: PropTypes.any,
  organisationId: PropTypes.string.isRequired,
  orgUniqueId: PropTypes.string.isRequired,
  archived: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  subscriptions: PropTypes.object.isRequired,
  isDefaultSearch: PropTypes.bool.isRequired,
  canManageAssignments: PropTypes.bool.isRequired,
  orgStudentsDataRecency: PropTypes.object.isRequired,
  orgStaffDataRecency: PropTypes.object.isRequired,
  orgClassesDataRecency: PropTypes.object.isRequired,
  orgLicencesDataRecency: PropTypes.object.isRequired,
  orgSubscriptionsDataRecency: PropTypes.object.isRequired,
  openEditPanel: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  orgDetails: PropTypes.object.isRequired
};

export default compose(
  withDataRecency('orgStudents', 'orgStaff', 'orgClasses', 'orgLicences', 'orgSubscriptions'),
  withLocalizedContent('mySchoolPage'),
  connect((state, props) => {
    const authUser = state.identity;
    const org = state.organisations.data[props.organisationId] || {};

    return {
      name: org.name || '',
      orgRole: org.role,
      archived: org.archived,
      orgUniqueId: org.customId,
      subscriptions: state.subscriptions,
      isDefaultSearch: state.search?.orgSubscriptions?.defaultSearch,
      canManageAssignments: createAuthorizeUpdateAssignments(state)({ orgId: props.organisationId }),
      userRole: authUser.role,
      orgDetails: org
    };
  })
)(ActiveOrganisation);
