import * as t from '../actionTypes';

const initialState = {
  data: {}
};

const _getLevels = dataObj => {
  const newDataObj = dataObj;
  if (newDataObj.levels.list.length !== 0) {
    newDataObj.levels.list.forEach(levelObj => {
      levelObj.name = levelObj.name.split(' ').pop();
    });
    newDataObj.levels.list = newDataObj.levels.list.sort((prev, curr) => prev.id - curr.id);
  }
  return newDataObj;
};

const _mapClassrooms = data =>
  Object.entries(data.classes).map(([id, classroom]) => ({ id, name: classroom.name, archived: classroom.archived }));

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_REQUEST_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            orgDetails: {
              loading: true,
              success: false,
              failure: false
            },
            loading: true,
            success: false,
            failure: false
          }
        }
      };
    case t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_SUCCESS_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            orgDetails: payload.data,
            loading: false,
            success: true,
            failure: false,
            group: {}
          }
        }
      };
    case t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            orgDetails: state.data[payload.orgId]?.orgDetails,
            loading: false,
            success: false,
            failure: true
          }
        }
      };
    case t.GRADEBOOK_CLASS_REPORT_ENVIRONMENT_FAILURE_ADD_ORG_DETAILS:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            orgDetails: {
              id: payload.orgId,
              name: payload.orgName,
              classrooms: [],
              locationId: payload.locationId
            }
          }
        }
      };
    case t.GRADEBOOK_GROUP_CLASS_REPORT_REQUEST_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            orgDetails: state.data[payload.orgId]?.orgDetails,
            loading: true,
            success: false,
            failure: false
          }
        }
      };
    case t.GRADEBOOK_GROUP_CLASS_REPORT_FAILURE_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            orgDetails: state.data[payload.orgId]?.orgDetails,
            loading: false,
            success: false,
            failure: true,
            group: {},
            errors: {
              ...state.errors,
              ...payload
            }
          }
        }
      };
    case t.GRADEBOOK_GROUP_CLASS_REPORT_SUCCESS_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            ...state.data[payload.orgId],
            loading: false,
            success: true,
            group: _getLevels(payload.data)
          }
        }
      };
    case t.GRADEBOOK_LEVEL_PANEL_REQUEST_V2:
      return {
        ...state,
        data: {
          [payload.orgId]: {
            ...state.data[payload.orgId],
            levelLoading: true,
            levelPanelRequest: payload.data
          }
        }
      };
    case t.GRADEBOOK_LEVEL_PANEL_ERROR_V2:
      return {
        ...state,
        data: {
          [payload.orgId]: {
            ...state.data[payload.orgId],
            levelLoading: false,
            errors: payload.error
          }
        }
      };
    case t.GRADEBOOK_LEVEL_PANEL_SUCCESS_V2:
      return {
        ...state,
        data: {
          [payload.orgId]: {
            ...state.data[payload.orgId],
            levelLoading: false,
            level: {
              ...state.data[payload.orgId].level,
              ...payload.panel
            }
          }
        }
      };
    case t.GET_CLASSROOMS_REQUEST_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            ...state.data[payload.orgId],
            orgDetails: {
              ...state.data[payload.orgId]?.orgDetails,
              loading: true,
              success: false,
              failure: false
            }
          }
        }
      };
    case t.GET_CLASSROOMS_SUCCESS_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            ...state.data[payload.orgId],
            orgDetails: {
              ...state.data[payload.orgId]?.orgDetails,
              classrooms: _mapClassrooms(payload.classes),
              loading: false,
              success: true,
              failure: false
            }
          }
        }
      };
    case t.GET_CLASSROOMS_FAILURE_V2:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.orgId]: {
            ...state.data[payload.orgId],
            orgDetails: {
              ...state.data[payload.orgId]?.orgDetails,
              loading: false,
              success: false,
              failure: true
            }
          }
        }
      };
    default:
      return state;
  }
};
