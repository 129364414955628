import { select } from 'redux-saga/effects';
import { isPlacementCentre } from '@oup/shared-node-browser/org.js';

import registerSupervisedUserApi from '../../../../apiCalls/registerSupervisedUserApi.js';
import enrolUserApi from '../../../../apiCalls/enrolUserApi.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';
import userRoles from '../../../../../../globals/userRoles';
import { getCurrentPlatform, isEltAssessmentMode } from '../../../../../../utils/platform';
import isTestingUser from '../../../../../../utils/verifyUser';

export default function* enrolUserRequest(usernameGenerated) {
  const getClassDetails = (classRooms, selectedClassRoomIds, roleName) => {
    if (featureIsEnabled('add-to-class-field-on-invite-student-side-panel')) {
      const existingClass = [];
      const newClass = [];
      let roleNameForClassMapping = roleName;
      if (roleName === userRoles.ORG_ADMIN || roleName === userRoles.TEACHER_ADMIN)
        roleNameForClassMapping = userRoles.TEACHER;
      selectedClassRoomIds.forEach(classRoomId => {
        if (classRooms[classRoomId] && classRooms[classRoomId].tempNewClass) {
          const { name } = classRooms[classRoomId];
          newClass.push({ className: name, userRoleName: roleNameForClassMapping });
        } else {
          existingClass.push({ userRoleName: roleNameForClassMapping, groupId: classRoomId });
        }
      });
      return { existingClass, newClass };
    }

    return {};
  };

  const getPlacementTestDetails = (placementTestSessionId, roleName) => ({
    existingClass: [
      {
        userRoleName: roleName,
        groupId: placementTestSessionId
      }
    ],
    newClass: []
  });

  const preventEmail = (email, orgConfig) => {
    if (isEltAssessmentMode() && featureIsEnabled('opt-and-ote-emails-disabled') && isTestingUser(email)) {
      return 'ote-initial-migration-2022';
    }

    if (isPlacementCentre(orgConfig)) {
      return 'opt-main-features';
    }

    return '';
  };

  const isOUPStaff = yield select(state => state.identity.role === userRoles.OUP_ADMIN);

  const {
    actioningUserId,
    orgId,
    orgName,
    orgFriendlyId,
    orgCountryCode,
    userName,
    userNameWithoutOrg,
    invitePending,
    firstName,
    lastName,
    email,
    roleName,
    classrooms,
    selectedClassroomIds,
    placementTestSessionId,
    orgConfig,
    isYoungLearner
  } = yield select(state => ({
    // Context IDs:
    userName: state.enrolUser.username,
    userNameWithoutOrg: state.enrolUser.usernameWithoutOrg,
    actioningUserId: state.identity.userId,
    orgId: isOUPStaff ? state.organisationPage.orgId : state.identity.currentOrganisationId,
    orgName: state?.organisations?.data?.[state.identity.currentOrganisationId]?.name || '',
    orgConfig: state?.organisations?.data?.[state.identity.currentOrganisationId]?.orgConfig,
    orgFriendlyId: state?.organisations?.data?.[state.identity.currentOrganisationId]?.customId || '',
    orgCountryCode: state?.organisations?.data?.[state.identity.currentOrganisationId]?.countryCode || '',
    invitePending: state.enrolUser.invitePending,

    // Details of user to create:
    firstName: state.enrolUser.firstNameValue,
    lastName: state.enrolUser.lastNameValue,
    email: state.enrolUser.emailValue,
    roleName: state.enrolUser.roleValue,
    classrooms: state.classrooms.data,
    selectedClassroomIds: state.organisationPage.selectedClassroomIds,
    placementTestSessionId:
      state?.placementOnboardingWizard?.placementTestSessionIdCreated ||
      state?.placementTestSessionCreate?.placementTestSessionId,
    isYoungLearner: state.enrolUser.isYoungLearner
  }));

  if (usernameGenerated) {
    return yield registerSupervisedUserApi(orgId, {
      firstName,
      lastName,
      existingClassIds: placementTestSessionId ? [placementTestSessionId] : selectedClassroomIds,
      orgFriendlyId,
      userName,
      userNameWithoutOrg,
      orgCountryCode,
      ...(featureIsEnabled('young-learners') && { isYoungLearner })
    });
  }
  // Assemble request body to send to API:
  const payload = {
    actioningUserId,
    orgName,
    orgFriendlyId,
    orgCountryCode,
    invitePending,
    firstName,
    lastName,
    email,
    roleName,
    platformCode: getCurrentPlatform(),
    preventEmailBecause: preventEmail(email, orgConfig),
    ...(placementTestSessionId
      ? getPlacementTestDetails(placementTestSessionId, roleName)
      : getClassDetails(classrooms, selectedClassroomIds, roleName)),
    ...(featureIsEnabled('young-learners') && { isYoungLearner })
  };

  console.log(`[enrolUser Saga] Submitting request to create user: ${JSON.stringify(payload)}.`);
  return yield enrolUserApi(orgId, payload);
}
