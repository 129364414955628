import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import getExternalIds from './getExternalIds.saga';
import searchProduct from './searchProducts.saga';
import generateDownloadLinks from './generateDownloadLinks.saga';

// eslint-disable-next-line func-names
export default function*() {
  yield takeLatest(t.CREATE_PRODUCT_GET_EXTERNAL_IDS, data => getExternalIds(data));
  yield takeLatest(t.SEARCH_PRODUCT_REQUEST, data => searchProduct(data));
  yield takeLatest(t.GENERATE_DOWNLOAD_LINKS_REQUEST, data => generateDownloadLinks(data));
}
