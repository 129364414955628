import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ValidationStatus.scss';

export const validationStatusCodes = {
  ERROR_STATUS: 'error',
  WARNING_STATUS: 'warning',
  NOTICE_STATUS: 'notice',
  WAITING_STATUS: 'waiting',
  VALID_STATUS: 'valid'
};

const glyphFor = {
  [validationStatusCodes.ERROR_STATUS]: GLYPHS.ICON_ERROR_CIRCLE,
  [validationStatusCodes.WARNING_STATUS]: GLYPHS.ICON_WARNING_CIRCLE,
  [validationStatusCodes.NOTICE_STATUS]: GLYPHS.ICON_INFORMATION_CIRCLE,
  [validationStatusCodes.WAITING_STATUS]: GLYPHS.ICON_LOADING,
  [validationStatusCodes.VALID_STATUS]: GLYPHS.ICON_CHECK_CIRCLE
};

function ValidationStatus({
  children,
  message,
  forId,
  type = validationStatusCodes.ERROR_STATUS,
  isActive = null,
  hideIcon = false,
  isHtml = false,
  containerClassName,
  arrowClassName,
  ...props
}) {
  const describedby = isActive ? `validationMessageFor-${forId}` : null;
  let status;
  switch (isActive) {
    case false:
      status = validationStatusCodes.VALID_STATUS;
      break;
    case true:
      status = type;
      break;
    default:
      status = '';
  }

  return (
    <div {...props} className={containerClassName}>
      <div className={styles.container}>
        <div className={styles.children}>
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              aria: {
                ...child.props.aria,
                invalid: isActive,
                describedby
              }
            })
          )}
        </div>
        {!hideIcon && [true, false].includes(isActive) ? (
          <SVGIcon className={styles.icon} glyph={glyphFor[status]} />
        ) : null}
      </div>
      {message && isActive ? (
        <div
          id={describedby}
          className={classnames(
            styles.status,
            styles[status],
            { [styles.iconHidden]: hideIcon },
            arrowClassName ? styles[arrowClassName] : ''
          )}
          role="alert"
        >
          {/* eslint-disable-next-line react/no-danger */}
          {isHtml ? <div className={styles.htmlNotice} dangerouslySetInnerHTML={{ __html: message }} /> : message}
        </div>
      ) : null}
    </div>
  );
}

ValidationStatus.propTypes = {
  children: PropTypes.any.isRequired,
  message: PropTypes.string,
  forId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(glyphFor)),
  isActive: PropTypes.bool,
  hideIcon: PropTypes.bool,
  isHtml: PropTypes.bool,
  containerClassName: PropTypes.string,
  arrowClassName: PropTypes.string
};

export default ValidationStatus;
