import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@oup/shared-front-end/src/components/Link';
import LegacyLink from '../Link/Link';
import colors, { validColors } from '../../globals/colors';
import { fgClass } from '../../utils/colorClassNames';
import SVGIcon, { GLYPHS, validGlyphs } from '../SVGIcon/SVGIcon';
import styles from './EntityRepresentation.scss';
import { validSizes, SIZES } from '../Thumbnail/Thumbnail';
import ContentWithThumbnail from '../ContentWithThumbnail/ContentWithThumbnail';
import { ALIGNMENT, LICENSE_STATUS_COLOR } from '../../globals/appConstants';
import Button, { buttonTypes } from '../Button/Button.js';
import Tooltip from '../Tooltip/Tooltip';
import { featureIsEnabled } from '../../globals/envSettings';
import { isHubMode } from '../../utils/platform';

// For convenience:
export { SIZES, validSizes };

const colorFor = {
  TEXT: colors.SUB_TEXT,
  SUCCESS: colors.CORRECT,
  WARNING: colors.WARNING,
  ERROR: colors.ERROR
};
const glyphFor = {
  ERROR: GLYPHS.ICON_ERROR_CIRCLE,
  WARNING: GLYPHS.ICON_WARNING_CIRCLE,
  NOTICE: GLYPHS.ICON_INFORMATION_CIRCLE,
  WAITING: GLYPHS.ICON_LOADING,
  SUCCESS: GLYPHS.ICON_CHECK_CIRCLE,
  COPY: GLYPHS.ICON_COPY
};

const getLineOneElement = (line1Text, line1Color, line1Badge, linkTo, headerSizeName, dangerouslySetLine1) => {
  let element;

  if (linkTo) {
    element = line1Text;
  } else if (headerSizeName) {
    return <h2 className={fgClass(line1Color)}>{line1Text}</h2>;
  } else if (dangerouslySetLine1) {
    // eslint-disable-next-line react/no-danger, max-len
    element = (
      <p
        className={classnames([fgClass(line1Color), styles.linealignment])}
        dangerouslySetInnerHTML={{ __html: line1Text }}
      />
    );
  } else {
    element = <p className={classnames([fgClass(line1Color), styles.line1Text, styles.linealignment])}>{line1Text}</p>;
  }

  return (
    <div>
      {element}
      {line1Badge && (
        <span className={classnames([styles.badgeWithMargin, styles.badge, fgClass(line1Color)])}>{line1Badge}</span>
      )}
    </div>
  );
};

/** This is a component with a Thumbnail and two text items next to it, typically a name and some subtext. The name can be a link. */
function EntityRepresentation({
  // Thumbnail props
  thumbnailColor,
  thumbnailSize,
  thumbnailGlyph,
  thumbnailText,
  thumbnailImageSrc,
  thumbnailSquareImg,
  secondaryThumbnailSize,
  secondaryThumbnailGlyph,
  smallCircleBackgroundColor,
  smallCircleGlyph,
  smallCircleText,
  // Content props
  linkId,
  linkTo,
  externalLink,
  headerSizeName,
  line1Color = colors.PRIMARY_BLUE,
  label = 'Copy class code',
  line1Text,
  line2Text,
  line3Text,
  line3Html,
  line4Text,
  line1Badge,
  line2Badge,
  line3Badge,
  // Customise content colour/style:
  status = LICENSE_STATUS_COLOR.TEXT,
  specificGlyph,
  textAlignment = ALIGNMENT.center,
  deletedStyle,
  id,
  dangerouslySetLine1,
  onClick,
  isNewEntity,
  ellipsisHeading,
  statusIndication,
  copyClipboard,
  toolTipText,
  joiningCode,
  customOrgId,
  userUpdateSuccess = false,
  helpAndSupportLink,
  helpAndSupportLinkTo
}) {
  const isClassFeatureEnabled = featureIsEnabled('class-toggle-remove');
  const buttonIds = `copyCodeId-${joiningCode?.code}`;

  const updateTheTooltipText = (buttonId, text) => {
    const copyButton = document.getElementById(buttonId);
    copyButton?.setAttribute('data-tooltip', text);
    copyButton?.setAttribute('aria-label', text);
    copyButton?.blur();
  };
  const content = (
    <ContentWithThumbnail
      id={linkTo ? null : id}
      backgroundColor={thumbnailColor}
      size={thumbnailSize}
      glyph={thumbnailGlyph}
      secondaryThumbnailSize={secondaryThumbnailSize}
      secondaryThumbnailGlyph={secondaryThumbnailGlyph}
      text={thumbnailText}
      imageSrc={thumbnailImageSrc}
      squareImg={thumbnailSquareImg}
      disabled={deletedStyle}
      smallCircleBackgroundColor={smallCircleBackgroundColor}
      smallCircleGlyph={smallCircleGlyph}
      smallCircleText={smallCircleText}
      isNewEntity={isNewEntity}
    >
      <div
        className={classnames(styles.text, styles[`${textAlignment}Alignment`], {
          [styles.deletedStyle]: deletedStyle,
          [styles.ellipsisText]: ellipsisHeading
        })}
      >
        {getLineOneElement(line1Text, line1Color, line1Badge, linkTo, headerSizeName, dangerouslySetLine1)}
        {line2Text && isClassFeatureEnabled && (
          <p className={styles.line2Color} onMouseLeave={() => updateTheTooltipText(buttonIds, label)}>
            {line2Text}{' '}
            {copyClipboard && (
              <Button
                id={buttonIds}
                iconOnly
                title={label}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  const input = document.createElement('input');
                  input.setAttribute('value', `${customOrgId}-${joiningCode?.code}`);
                  document.body.appendChild(input);
                  input.select();
                  const result = document.execCommand('copy');
                  document.body.removeChild(input);
                  updateTheTooltipText(buttonIds, 'Code copied!');
                  return result;
                }}
                type={buttonTypes.COPY_CLIPBOARD}
                preventDefault={false}
              />
            )}{' '}
            {toolTipText && (
              <Tooltip title={toolTipText} onClick={e => e.preventDefault()} className={styles.largeTooltip}>
                <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
              </Tooltip>
            )}
          </p>
        )}
        {line2Badge && <span className={classnames(styles.badge, styles.line2Color)}>{line2Badge}</span>}
        {(line3Text || line3Html) && (
          <p
            className={
              userUpdateSuccess
                ? classnames([styles.line3color, styles.linealignment])
                : classnames([fgClass(colorFor[status]), styles.linealignment])
            }
          >
            {(statusIndication || specificGlyph) && (
              <SVGIcon
                className={styles.statusIndication}
                glyph={specificGlyph || glyphFor[status] || glyphFor.NOTICE}
              />
            )}
            {/* eslint-disable-next-line react/no-danger */}
            {line3Text || <span dangerouslySetInnerHTML={{ __html: line3Html }} />}
            {helpAndSupportLink && (
              <LegacyLink openInNewTab to={helpAndSupportLinkTo} className={styles.helpAndSupportLink} underlined>
                {helpAndSupportLink}
                <SVGIcon glyph={GLYPHS.ICON_LINK} className={styles.linkGlyph} />
              </LegacyLink>
            )}
          </p>
        )}
        {line3Badge && <span className={styles.badge}>{line3Badge}</span>}
        {line4Text && <span className={styles.line4Text}>{line4Text}</span>}
      </div>
    </ContentWithThumbnail>
  );

  return !linkTo ? (
    <div className={styles.entityWrapper}>{content}</div>
  ) : (
    // Need to wrap the Link in an element containing the id for test compatibility
    <div id={id} className={styles.entityWrapper}>
      <Link
        id={linkId}
        to={
          externalLink
            ? `${linkTo.indexOf('//') === -1 && linkTo.indexOf('https://') === -1 ? '//' : ''}${linkTo}`
            : linkTo
        }
        onClick={onClick}
        target={externalLink ? '_blank' : '_self'}
        underline={!!isHubMode()}
        component={RouterLink}
      >
        {content}
        {externalLink && <span className="a11y-hide"> (Link opens in a new tab)</span>}
      </Link>
    </div>
  );
}

EntityRepresentation.propTypes = {
  // Thumbnail props
  /** The color of the thumbnail. Only required if not an image */
  thumbnailColor: PropTypes.oneOf(validColors),
  /** The size of the thumbnail. */
  thumbnailSize: PropTypes.oneOf(validSizes),
  /** The icon to be used by the thumbnail. */
  thumbnailGlyph: PropTypes.oneOf(validGlyphs),
  /** Thes text to be used for the Thumbnail (instead of an icon). */
  thumbnailText: PropTypes.string,
  /** Thes image to be used for the Thumbnail (instead of an icon). */
  thumbnailImageSrc: PropTypes.string,
  /** If the image source is for a book cover then squareImg should be set. */
  thumbnailSquareImg: PropTypes.bool,
  /** The size of the secondary thumbnail. */
  secondaryThumbnailSize: PropTypes.oneOf(validSizes),
  /** The icon to be used by the secondary thumbnail. */
  secondaryThumbnailGlyph: PropTypes.oneOf(validGlyphs),

  // Content props
  /** Link ID for testing if needed */
  linkId: PropTypes.string,
  /** Target of the link if the entity contains a link */
  linkTo: PropTypes.string,
  externalLink: PropTypes.bool,
  /** First line text, typically a name */
  line1Text: PropTypes.string.isRequired,
  headerSizeName: PropTypes.bool,
  /** Second line text, not always needed */
  line2Text: PropTypes.string,
  /** Third line text, not always needed */
  line3Text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  line3Html: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  line4Text: PropTypes.string,
  /** Optionally customise the status color of the line3Text. */
  status: PropTypes.oneOf(Object.keys(colorFor)),
  specificGlyph: PropTypes.any,
  /** This applies a "deleted" style to the element */
  deletedStyle: PropTypes.bool,
  /** The html ID of the element. */
  id: PropTypes.string,
  /** Allows the first line to be set with dangerouslySetInnerHTML to support markdown */
  dangerouslySetLine1: PropTypes.bool,
  /** Text of the badge */
  line1Badge: PropTypes.string,
  line2Badge: PropTypes.string,
  line1Color: PropTypes.string,
  line3Badge: PropTypes.string,
  label: PropTypes.string,
  smallCircleBackgroundColor: PropTypes.oneOf(validColors),
  smallCircleGlyph: PropTypes.oneOf(validGlyphs),
  smallCircleText: PropTypes.string,
  onClick: PropTypes.func,
  /** Entity is new */
  isNewEntity: PropTypes.bool,
  userUpdateSuccess: PropTypes.bool,
  /** Entity heading text is ellipsis */
  ellipsisHeading: PropTypes.bool,
  /** Entity heading with status icon */
  statusIndication: PropTypes.bool,
  /** Adds a copy clipboard button on line 2 */
  copyClipboard: PropTypes.bool,
  /** Adds an info button on line 2 that will open a small modal containing info string */
  toolTipText: PropTypes.string,
  /** Joining code used for copy clipboard */
  joiningCode: PropTypes.object,
  /** Custom org id used for copy clipboard */
  customOrgId: PropTypes.string,
  /** Text alignment */
  textAlignment: PropTypes.oneOf(Object.values(ALIGNMENT)),
  helpAndSupportLink: PropTypes.string,
  helpAndSupportLinkTo: PropTypes.string
};

export default EntityRepresentation;
