import initAuth0 from '../globals/oidcSettings';
import { clearCredentialData } from './credentials.js';

const destroyLocalSession = async (redirectTo, openNewTab = false) => {
  clearCredentialData(true);
  await initAuth0().then(async auth0 => {
    try {
      await auth0.logout({ localOnly: true });
    } catch (error) {
      console.log('Error authorising', error);
    }
  });
  if (redirectTo && openNewTab) {
    window.open(redirectTo, '_blank');
  } else {
    window.location.href = redirectTo;
  }
};

export default destroyLocalSession;
