import { put } from 'redux-saga/effects';

import {
  generateManagedUserSignInCardSuccess,
  generateManagedUserSignInCardFailure
} from '../../../../actions/managedUser';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* generateManagedUserSignInCard(orgId, userId) {
  const response = yield signedFetch(
    'generateSignedURL',
    `${__API_BASE__}/org/${orgId}/managed-user/${userId}/generate-signed-url`,
    'GET'
  );

  if (response.status === 'success') {
    yield put(generateManagedUserSignInCardSuccess(response.data.signedURL));
    return;
  }

  yield put(generateManagedUserSignInCardFailure());
}

export default generateManagedUserSignInCard;
