import { put, take, all } from 'redux-saga/effects';
import { showConfirmation, showErrorReview, SUBMIT_FORM } from '../../../../reducers/joinClass.reducer.js';
import { postNotificationMessage } from '../../../../actions/postMessage';
import joinClassRequest from './joinClassRequest.js';

export default function* redeemClassCode() {
  console.log('[redeemClassCode Saga] Beginning');

  while (true) {
    console.log('[redeemClassCode Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);
    console.log('[redeemClassCode Saga] Submitting Enrol User In Class request');
    const result = yield joinClassRequest();
    const status = result.status?.toLowerCase();
    console.log(result);
    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? { code: result.code, message: result.message } : null;
    const classroomName = result.data?.groupName || '';

    console.log('[redeemClassCode Saga] Enrol User in Class request complete, showing confirmation page');
    if (!failed) {
      yield all([
        put(showConfirmation(failed, apiError, classroomName)),
        put(postNotificationMessage({ eventName: 'REDEEM_CODE_SUCCESS', viewId: '*' }))
      ]);
    } else {
      console.log('[redeemClassCode Saga] Class joining code may be incorrect or already in class. Throw error');
      yield put(showErrorReview(apiError));
    }
  }
}
