import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ManagedUserChangePasswordPanel } from '../../../panels';

function UnlockAccount({ userIds = [], ...props }) {
  return (
    <ManagedUserChangePasswordPanel
      title="This account is locked"
      detail="You must set a new password to unlock this account"
      multiple={userIds.length > 1}
      userId={userIds[0]}
      userLocked
      {...props}
    />
  );
}

UnlockAccount.propTypes = {
  ...ManagedUserChangePasswordPanel.propTypes,
  userIds: PropTypes.arrayOf(PropTypes.string)
};

export default connect(state => ({ userIds: state.unlockAccount.userIds }))(UnlockAccount);
