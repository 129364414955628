import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import USER_ROLES, { validUserRoles } from '../globals/userRoles.js';
import {
  isEmbeddedInIframe,
  isOrbMode,
  isOteMode,
  isOicMode,
  isEltAssessmentMode,
  getCurrentPlatform,
  getPlatformFromQueryString
} from '../utils/platform';
import destroyLocalSession from '../utils/destroyLocalSession';
import {
  getPlatformAmStudentUrl,
  getPlatformAmStaffUrl,
  getIntegrationPlatformKey,
  featureIsEnabled
} from '../globals/envSettings';
import { orgRoles, eltAmRoles } from '../globals/orgRoles';
import LoadingPage from '../structure/StatePages/LoadingPage/LoadingPage';
import { formStates } from '../redux/reducers/invitationsBanner.reducer';
import { queryFilter, redirectToAm } from '../utils/url.js';
import isTestingUser from '../utils/verifyUser';

// Site homepage
import Homepage from './Homepage/Homepage.js';
import OteHomepage from './OteHomepage/OteHomepage.js';
import OicHomepage from './OicHomepage/OicHomepage.js';

class IndexRedirectHandler extends Component {
  componentWillMount() {
    let nextPage;
    const { history, userRole, invitesFetchStatus, invites } = this.props;
    const urlParams = window.location.search;
    const returnTo = urlParams && (urlParams.match(/returnTo=([^&$]+)/) || [])[1];
    const nextPathFromSessionStorage = sessionStorage.getItem('goto-path');

    if (nextPathFromSessionStorage && nextPathFromSessionStorage !== '/login') {
      nextPage = nextPathFromSessionStorage;
    } else if (returnTo && returnTo !== encodeURIComponent('/') && returnTo !== encodeURIComponent('/login')) {
      // Redirect to where the user originally wanted to go:
      nextPage = decodeURIComponent(returnTo);
    } else if (!userRole) {
      // Redirect to homepage if user is not logged in:
      nextPage = '/';
    } else if (invitesFetchStatus) {
      // Stop redirection until we get invites results:
      return;
    } else if (!invitesFetchStatus && invites && invites.length > 0) {
      nextPage = 'showInvitations';
    } else {
      // We should redirect the user to their relevant "home page":
      nextPage = this.getRedirectPath(userRole);
    }

    if (nextPathFromSessionStorage) sessionStorage.removeItem('goto-path');

    const filteredParams = queryFilter(urlParams, ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']);
    const newParams = filteredParams ? `?${filteredParams}` : '';

    if (nextPage.startsWith('http://') || nextPage.startsWith('https://')) {
      window.location.href = `${nextPage}${newParams}`;
    } else {
      history.replace(`${nextPage}${newParams}`);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history, invites, invitesFetchStatus, userRole } = nextProps;
    const isImpersonationMode = !!localStorage.getItem('impersonation-token');
    // clear invites array while in impersonation mode so no invitation can be accepted on behalf of the user
    if (isImpersonationMode) {
      invites.length = 0;
    }
    if (!invitesFetchStatus && invites.length > 0) {
      // Redirect to showInvitations route if we have more than 0 invite:
      history.replace('showInvitations');
    } else if (invites.length === 0 && !invitesFetchStatus && !!userRole) {
      let redirectPath = this.getRedirectPath(userRole, redirectToAm());
      // Redirecting ORB user to orb-start-tips route.
      if (localStorage.getItem('showStartUpPages') === 'true' && isOrbMode()) {
        redirectPath = 'orb-start-tips';
      }
      if (redirectPath.startsWith('http://') || redirectPath.startsWith('https://')) {
        if (!isOteMode()) {
          window.location.href = redirectPath;
        } else {
          destroyLocalSession(redirectPath);
          if (redirectToAm() && userRole === USER_ROLES.ORG_ADMIN) {
            sessionStorage.setItem('goto-path', '/mySchool');
          }
        }
      } else {
        history.replace(redirectPath);
      }
    }
  }

  // method to get redirect route based on userRole:
  // eslint-disable-next-line class-methods-use-this
  getRedirectPath = (userRole, redirectToAM) => {
    let nextPage;
    let platform;
    switch (userRole) {
      case USER_ROLES.MANAGED_USER:
      case USER_ROLES.LEARNER:
        if (isEltAssessmentMode()) {
          platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());
          nextPage = getPlatformAmStudentUrl(platform);
          destroyLocalSession();
        } else {
          nextPage = 'myProfile/learningMaterial';
        }
        break;
      case USER_ROLES.TEACHER:
        if (isOrbMode()) {
          nextPage = '/teacherHome/library';
        } else if (isEltAssessmentMode()) {
          platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());
          nextPage = getPlatformAmStaffUrl(platform);
          destroyLocalSession();
        } else {
          nextPage = 'myProfile/myClasses';
        }
        break;
      case USER_ROLES.TEACHER_ADMIN:
        nextPage = isOrbMode() ? '/teacherHome/library' : 'mySchool/students';
        break;
      case USER_ROLES.ORG_ADMIN:
        if (isOrbMode()) {
          nextPage = '/teacherHome/library';
        } else if (isEltAssessmentMode()) {
          if (redirectToAM) {
            platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());
            nextPage = getPlatformAmStaffUrl(platform);
            destroyLocalSession();
          } else {
            nextPage = 'mySchool';
          }
        } else {
          nextPage = 'mySchool';
        }

        break;
      case USER_ROLES.OUP_SUPPORT:
      case USER_ROLES.OUP_ADMIN:
        nextPage = isOrbMode() ? '/teacherHome/library' : 'mySchool/organisations';
        break;
      default:
        nextPage = 'myProfile';
    }

    return nextPage;
  };

  // This only happens when one of the conditions above is NOT causing a REDIRECT:
  render() {
    const { invitesFetchStatus } = this.props;
    const hideHeader = isEmbeddedInIframe() || sessionStorage.getItem('embedded-by-url') === 'true';

    // show LoadingPage until we get invites:
    if (invitesFetchStatus) {
      return <LoadingPage showHeader={!hideHeader} customLogoComponent={isOrbMode() ? <div /> : null} />;
    }

    if (isOteMode() && redirectToAm()) {
      return <LoadingPage showHeader={!hideHeader} />;
    }
    if (isOteMode()) {
      return <OteHomepage />;
    }
    if (isOicMode()) {
      return <OicHomepage />;
    }
    return <Homepage />;
  }
}

const filteredInvites = (unfilteredInvites = [], email = '') => {
  if (isOteMode()) {
    return featureIsEnabled('opt-and-ote-emails-disabled') && isTestingUser(email)
      ? []
      : unfilteredInvites.filter(invite => invite.groupRole === orgRoles.TEST_CENTER);
  }
  // temporary solution for excluding AM invites from non AM platforms
  return unfilteredInvites.filter(invite => !eltAmRoles.includes(invite.groupRole));
};

IndexRedirectHandler.propTypes = {
  /** Our current user's role */
  userRole: PropTypes.oneOf(validUserRoles),
  invitesFetchStatus: PropTypes.bool,
  invites: PropTypes.array,
  history: PropTypes.shape({
    replace: PropTypes.func
  })
};

export default connect(state => ({
  userRole: state.identity.role,
  invitesFetchStatus: state.invites.formState !== formStates.INPUTTING && !!state.identity.role,
  invites: filteredInvites(state.invites.invites, state.identity.email ? state.identity.email : '')
}))(withRouter(IndexRedirectHandler));
